<template>
  <div>
    <h2>
      Request Data | Total: {{ total_amount }} | Print |
      <v-icon
        v-if="!is_deleting_request && request_data.length > 0"
        color="success"
        @click="print_data()"
      >
        {{ icons.mdiPrinter }}
      </v-icon>
      <v-progress-circular
        color="info"
        indeterminate
        v-if="is_deleting_request"
      ></v-progress-circular>
    </h2>
    <v-simple-table dense class="mt-2" height="665">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center text-uppercase" v-if="is_ftv===1">From Bank</th>
          <th class="text-center text-uppercase" v-if="is_ftv===0">Branch</th>
          <th class="text-center text-uppercase" v-if="is_ftv===1">To Bank</th>
          <th class="text-center text-uppercase" v-if="is_ftv===0">Particulars</th>
          <th class="text-center text-uppercase">Amount</th>
          <th class="text-center text-uppercase">Description</th>
          <th class="text-center text-uppercase">Cheque Date</th>
          <th class="text-center text-uppercase">Payment For</th>
          <th class="text-center text-uppercase">Credit To</th>
          <th
            class="text-center text-uppercase"
            v-if="(is_have_pending_request && selectedDepositId === 0)||can_up_del"
          >
            Action
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in request_data" :key="item.id">
          <td class="text-center">
            {{
            is_ftv===1
            ? item.ftv_from_bank.bank_code + ' # ' + item.ftv_from_bank.account_no
            : item.branch.branch_code
            }}
          </td>
          <td class="text-center">
            {{
            is_ftv===1
            ? item.ftv_to_bank.bank_code + ' # ' + item.ftv_to_bank.account_no
            : item.particulars.particulars
            }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.amount) }}
          </td>
          <td class="text-center">
            {{ item.description }}
          </td>
          <td class="text-center">
            {{ item.cheque_date }}
          </td>
          <td class="text-center">
            {{ item.payment_for }}
          </td>
          <td class="text-center">
            {{ item.credit_to }}
          </td>
          <td class="text-center"
              v-if="(is_have_pending_request && selectedDepositId === 0)||can_up_del">
            <v-icon v-if="!is_deleting" class="mr-2" color="info"
                    @click="edit_request_data(item)">
              {{ icons.mdiPencil }}
            </v-icon>
            <v-icon v-if="!is_deleting" class="mr-2" color="error"
                    @click="delete_data(item.id)">
              {{ icons.mdiDelete }}
            </v-icon>
            <v-progress-circular
              color="info"
              indeterminate
              v-if="is_deleting"
            ></v-progress-circular>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
  import {
    mdiPencilOutline,
    mdiAccountSearchOutline,
    mdiDelete,
    mdiThumbDown,
    mdiThumbUp,
    mdiPencil,
    mdiGoogleCirclesCommunities,
    mdiAccountOutline,
    mdiPrinter,
    mdiCashCheck,
  } from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";

  export default {
    props: {
      total_amount: String,
      selectedDepositId: Number,
      month_of_id: Number,
      is_have_pending_request: Boolean,
      is_deleting_req: Boolean,
      can_up_del: Boolean,

      request_data: Array,
      month_of_items: Array,

      is_regular: Number,
      is_pdc: Number,
      is_ftv: Number,
    },
    mounted() {
      this.is_deleting_request = this.is_deleting_req;
    },
    setup() {
      return {
        is_deleting_request: false,
        is_deleting: false,
        icons: {
          mdiPencilOutline,
          mdiAccountSearchOutline,
          mdiDelete,
          mdiThumbDown,
          mdiThumbUp,
          mdiPencil,
          mdiPrinter,
          mdiCashCheck,
        },
      }
    },
    computed: {
      ...mapGetters('authentication', [
        'employee_id',
        'month_of',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('request', [
        'initialize_request_form',
        'register_request_form',
        'delete_request_form',
        'change_status_request_form',
      ]),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      edit_request_data(value) {
        this.$emit('edit_request_data', value)
      },
      delete_data(id) {
        this.$emit('delete_data', id)
      },
      async print_data() {
        this.is_deleting_request = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing

        widths = [20, 40, 40, 60, 110, 70, 50, 40, 55]
        var isftv = this.is_ftv === 1
        if (isftv) {
          payments_array.push([
            {text: 'Req. ID', alignment: 'left', style: 'main_info'},
            {text: 'Category', alignment: 'left', style: 'main_info'},
            {text: 'From', alignment: 'left', style: 'main_info'},
            {text: 'To', alignment: 'left', style: 'main_info'},
            {text: 'Description', alignment: 'left', style: 'main_info'},
            {text: 'Payment For', alignment: 'left', style: 'main_info'},
            {text: 'Schedule', alignment: 'left', style: 'main_info'},
            {text: 'Cheque#', alignment: 'left', style: 'main_info'},
            {text: 'Amount', alignment: 'left', style: 'main_info'},
          ])
        } else {
          payments_array.push([
            {text: 'Req. ID', alignment: 'left', style: 'main_info'},
            {text: 'Category', alignment: 'left', style: 'main_info'},
            {text: 'Branch', alignment: 'left', style: 'main_info'},
            {text: 'Particulars', alignment: 'left', style: 'main_info'},
            {text: 'Description', alignment: 'left', style: 'main_info'},
            {text: 'Payment For', alignment: 'left', style: 'main_info'},
            {text: 'Schedule', alignment: 'left', style: 'main_info'},
            {text: 'Cheque#', alignment: 'left', style: 'main_info'},
            {text: 'Amount', alignment: 'left', style: 'main_info'},
          ])
        }


        var tott = this.total_amount
        this.request_data.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.request_id,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.category.category,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: isftv ? item.ftv_from_bank.bank_code + ' # ' + item.ftv_from_bank.account_no : item.branch.branch_code,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: isftv ? item.ftv_to_bank.bank_code + ' # ' + item.ftv_to_bank.account_no : item.particulars.particulars,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.description,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.payment_for,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.cheque_date,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.cheque_no,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                (item.pdc_status === 0 ? 'Not Count: ' : '') +
                (item.amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: tott,
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of_id)
                      ].month_of,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'REQUEST #: ',
                  {
                    text: this.selectedDepositId,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'HILARIO GENERAL ENTERPRISE',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'REQUEST FORM',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'CEO',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.is_deleting_request = false
      },
    }
  }
</script>

<style scoped>
</style>
